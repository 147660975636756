<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ vacancy.entity.name }}</span>
    </v-card-title>
    <!-- <pre>{{test}}</pre> -->
    <v-card-text>
      <span
        class="text-justify black--text text-subtitle-1"
        v-html="$t('suportExplainHi') + ' ' + currentUser.name + ','"
      ></span>
      <br />
      <br />
      <span
        class="text-justify black--text text-subtitle-1"
        v-html="$t('info_vacancy_label').replace('ENTITY', vacancy.entity.name)"
      ></span>

      <v-row class="mt-1" v-for="(doc, index) in documents" :key="`${index}`">
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" md="6">
              <v-row no-gutters>
                <v-col cols="12" md="5">
                  <span class="text-body-2 black--text text-capitalize "
                    >{{ $t(doc.name) }}:
                  </span>
                </v-col>
                <v-col cols="12" md="5">
                  <v-row>
                    <upload-training-file
                      :files="doc.files"
                      @upload="uploadFile"
                      :no_doc="doc.no_doc"
                      :index="index"
                      @remove="removeTrainingFile"
                    />
                    <span class="error--text mx-3" style="margin-top: -5%;">{{
                      doc.message
                    }}</span></v-row
                  >
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                    :class="isMobile ? '' : 'ml-7'"
                    small
                    v-show="
                      doc.rule === 'rule_2' && !doc.files.length && !doc.no_doc
                    "
                    color="error"
                    @click="haveNot(index)"
                  >
                    <v-icon small>mdi-close</v-icon
                    >{{ $t("I_have_not") }}</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row class="d-flex flex-row justify-end align-center mt-5 px-6 py-8">
        <v-spacer></v-spacer>

        <v-btn color="primary" @click="$emit('close')" dense text>{{
          $t("cancel")
        }}</v-btn>

        <v-btn
          type="submit"
          color="primary"
          sense
          @click="submit"
          :disabled="v_btn"
          >{{ $t("btn_submit") }}</v-btn
        >
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import UploadTrainingFile from "./UploadTrainingFile.vue";
export default {
  name: "InfoVacancy",
  props: {
    test: Object,
    vacancy: Object,
  },
  components: { UploadTrainingFile },
  data: () => ({
    timeout: 900,
    snackbar: false,
    snackbar_test: "",
    files: [],
    file: undefined,
    documents: [],
    v_btn: false,
    isMobile: false,
  }),
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  mounted() {
    if (/Mobi|Android/i.test(window.navigator.userAgent)) {
      this.isMobile = true;
      console.log("É um dispositivo móvel");
    } else {
      this.isMobile = false;
      console.log("Não é um dispositivo móvel");
    }
    this.documents = this.vacancy.documents.map((d) => {
      return { ...d, files: [], no_doc: false, message: "" };
    });
  },
  methods: {
    closeSnackbar() {
      this.snackbar = false;
    },
    removeTrainingFile(index, i) {
      // this.files.splice(index, 1);
      this.documents[i].files.splice(index, 1);
    },

    submit() {
      if (
        this.documents.find((d) => d.files.length === 0 && d.rule === "rule_1")
      ) {
        this.documents = this.documents.map((d) => {
          if (!d.files.length && d.rule === "rule_1") {
            d.message = this.$t("required_field");
          }
          return { ...d };
        });
        this.v_btn = true;
      } else {
        this.v_btn = false;
        this.documents = this.documents.filter((d) => d.files.length);
        // console.log(this.documents);
        this.$emit("done", this.documents);
      }
    },

    haveNot(index) {
      this.documents[index].no_doc = true;
    },

    uploadFile(files, i) {
      for (let index = 0; index < files.length; index++) {
        const element = files[index];
        console.log("tamanho", element.size);
        if (element.size >= 1000000) {
          this.timeout = 2000;
          this.snackbar = true;
          this.snackbarColor = "error";
          this.snackbar_test = this.$t("The_document_cannot_exceed_2_MB");
          setTimeout(() => {
            this.snackbar = false;
          }, this.timeout);
        } else {
          // this.files.push(element.name);
          this.files = [element.name];
          this.documents[i].files.push(element);
          this.documents[i].no_doc = false;
          this.documents[i].message = "";
          this.v_btn = false;
        }
      }
    },
  },
};
</script>
<style scoped>
@import "./styles/input-file.css";
</style>
