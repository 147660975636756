<template>
  <v-dialog v-model="dialog" persistent max-width="400">
    <v-card>
      <v-card-text justify="center" class="pt-5 pb-0">
        <p class="text-subtitle-1 black--text text-center">
          {{ $t("share_title") }}
        </p>
        <!-- <div
          style="margin-top: -5%; text-align:center; margin-bottom:2%"
          class="caption"
        >
          {{ $t("share_content") }}
        </div> -->

        <div class="text-center">
          <ShareNetwork
            network="facebook"
            :url="
              `${appUrl}/vacancy/${vacancy.id}`
            "
            :title="vacancy.title"
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            :quote="vacancy.title"
            :hashtags="vacancy.title"
          >
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn color="blue" v-on="on">
                  <v-icon color="white">mdi-facebook</v-icon>
                </v-btn>
              </template>
              <span>Facebook</span>
            </v-tooltip>
          </ShareNetwork>
          
          <ShareNetwork
            network="linkedin"
            :url="
              `${appUrl}/vacancy/${vacancy.id}`
            "
            :title="vacancy.title"
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            :quote="vacancy.title"
            :hashtags="vacancy.title"
          >
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn class="mx-2" color="blue" v-on="on">
                  <v-icon color="white">mdi-linkedin</v-icon>
                </v-btn>
              </template>
              <span>Linkedin</span>
            </v-tooltip>
          </ShareNetwork>

          <ShareNetwork
            network="twitter"
            :url="
              `${appUrl}/vacancy/${vacancy.id}`
            "
            :title="vacancy.title"
            description="This week, I’d like to introduce you to 'Vite', which means 'Fast'. It’s a brand new development setup created by Evan You."
            :quote="vacancy.title"
            :hashtags="vacancy.title"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn color="blue" v-on="on">
                  <v-icon color="white">mdi-twitter</v-icon>
                </v-btn>
              </template>
              <span>twitter</span>
            </v-tooltip>
          </ShareNetwork>
          
        </div>
              <div class="d-flex flex-row pt-3">
          <v-tooltip v-model="show" top>
            <template v-slot:activator>
              <v-text-field
                v-model="linkShare"
                outlined
                dense
                readonly
                class="mr-2"
                @click:append="copyTestingCode"
              ></v-text-field>

              <v-btn
                type="button"
                text
                @click.stop.prevent="copyTestingCode"
                class=""
              >
                {{ $t("copy") }}
              </v-btn>
            </template>
            <span>{{ $t("copied") }}</span>
          </v-tooltip>
          <input type="hidden" id="testing-code" :value="linkShare" />
        </div>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-row no-gutters>
          <v-col md="12" sm="12">
            <v-btn
              color="primary"
              text
              @click="finish()"
              block
              >{{ $t("close") }} </v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { APP_URL } from '../../../api/';
export default {
  name: 'ShareDialog',
  data: () => ({
    appUrl: APP_URL,
    dialog: false,
    linkShare: "",
    show: false,
    vacancy: {}
  }),
  created() {
    // eslint-disable-next-line no-undef
    Fire.$on("shareVacancy", (vacancy) => {
      this.dialog = true
      this.vacancy = vacancy
      this.linkShare = `${this.appUrl}/vacancy/${vacancy.id}`
    });
  },
  methods: {
    copyTestingCode () {
      let testingCodeToCopy = document.querySelector('#testing-code')
      testingCodeToCopy.setAttribute('type', 'text')    // 不是 hidden 才能複製
      testingCodeToCopy.select()

      try {
        document.execCommand('copy');
        this.show = !this.show
        // var msg = successful ? 'successful' : 'unsuccessful';
        // alert('Testing code was copied ' + msg);
      } catch (err) {
        alert('Oops, unable to copy');
      }

      /* unselect the range */
      testingCodeToCopy.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    },
    finish () {
      this.dialog = false;
      this.$emit('finish');
    }
  }
}
</script>