<template>
  <div>
    <v-container class="fill-heigt pt-0" fluid>
      <v-row class="">
        <v-col cols="12" md="8" class="text-left px-1 mt-1">
          <v-row>
            <div class="text-left black--text  pt-7">
              <v-btn color="primary" text small class="text-capitalize">
                <span class="body-2 black--text font-weight-medium">{{
                  $t("Job_Openings")
                }}</span>
              </v-btn>
            </div>
            <!-- <div class="text-left black--text px-2 pt-7">
               <v-btn
                color="primary"
                style="background: #fdf0d7"
                text
                small
                class="text-capitalize"
              >
                <span class="body-2 black--text">{{ $t("spontaneou_application") }}</span>
              </v-btn>
            </div> -->
          </v-row>
        </v-col>
        <v-col cols="12" md="4" class="text-right px-0 mt-2">
          <v-row>
            <div class="px-0 pt-6">
              <vacancy-Filter
                @apply="applyVacancyFilter"
                @clean="applyVacancyFilter"
              />
            </div>
            <v-text-field
              class="px-2 pt-3"
              v-model="textSearch"
              append-icon="mdi-magnify"
              :label="$t('cand_search_position')"
              single-line
              hide-details
              dense
              outlined
            ></v-text-field
          ></v-row>
        </v-col>
      </v-row>
    </v-container>
    <v-divider class="my-1 mb-3"></v-divider>
    <!-- <v-row class="d-flex flex-row px-0">
      <div class="text-left black--text px-3" style="margin-top: 3%">
        {{ $t("Job_Openings") }}
      </div>
      <v-spacer></v-spacer>

      <v-col cols="3" md="2" class="text-right px-0 mt-1">
        <vacancy-Filter @apply="applyTestFilter" @clean="applyTestFilter" />
      </v-col>
      <v-col cols="9" md="4">
        <div class="text-right">
          <v-text-field
            v-model="textSearch"
            append-icon="mdi-magnify"
            :label="$t('cand_search_position')"
            single-line
            hide-details
            dense
            outlined
          ></v-text-field>
        </div>
      </v-col>
    </v-row> -->
    <v-row>
      <v-col
        cols="12"
        md="3"
        lg="3"
        xl="4"
        v-for="load in 8"
        :key="load"
        v-show="loading"
      >
        <v-skeleton-loader
          elevation="2"
          v-bind="filteredItems"
          type="image, article"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        md="3"
        lg="3"
        xl="4"
        v-for="vacancy in filteredItems"
        :key="vacancy.id"
      >
        <vacancy-card
          :vacancy="vacancy"
          :done="true"
          :apply_cand="false"
          @view="view"
        />
      </v-col>
    </v-row>
    <v-row v-if="!filteredItems.length">
      <v-col cols="12" md="12" class="text-center">
        {{ $t("no_vacancies") }}
      </v-col>
    </v-row>
    <share-dialog />
  </div>
</template>

<script>
import { GET_PRIVATE_VACANCIES_QUERY } from "./../graphql/Query";
import { mapActions, mapGetters } from "vuex";
import VacancyCard from "../../../components/VacancyCard.vue";
import ShareDialog from "../../../components/ShareDialog.vue";

import vacancyFilter from "./../../../components/vacancyFilter.vue";
import replaceSpecialCharsMixins from "@/mixins/replaceSpecialChars";
export default {
  name: "PrivateVacancy",
  components: { VacancyCard, ShareDialog, vacancyFilter },
  mixins: [replaceSpecialCharsMixins],
  data: () => ({
    vacancies: [],
    textSearch: undefined,
    filter: {
      areas: undefined,
      place: undefined,
    },
    appliedFilter: true,
    totalPages: 1,
    loading: true,
  }),
  apollo: {
    Vacancies: {
      query: GET_PRIVATE_VACANCIES_QUERY,
      variables() {
        return { filterVacancy: this.filter };
      },
      fetchPolicy: "no-cache",
      result({ loading }) {
        if (!loading) {
          this.loading = false;
        }
      },
    },
  },
  watch: {
    Vacancies: function(val) {
      if (this.appliedFilter) {
        this.setVacanciesState(val);
        this.appliedFilter = false;
      } else {
        this.setVacancyScrolled(val);
      }
    },
  },
  computed: {
    ...mapGetters({
      getVacancies: "vacancy/getVacancies",
    }),
    filteredItems() {
      let resultItems = [...this.getVacancies];
      if (this.textSearch) {
        const text = this.replaceSpecialChars(this.textSearch);
        resultItems = resultItems.filter((t) =>
          this.replaceSpecialChars(t.title).includes(text)
        );
      }
      resultItems = resultItems.filter((v) => v.entity != null);
      return resultItems;
    },
  },
  mounted() {
    if (this.show === true) {
      console.log("montei");
      this.applyFilter();
    }
  },
  methods: {
    ...mapActions({
      setVacanciesState: "vacancy/setVacancies",
      setVacancyScrolled: "vacancy/setVacancyScrolled",
    }),
    async applyFilter() {
      this.appliedFilter = true;
      this.fetch();
    },
    async fetch() {
      await this.$apollo.queries.vacancies.refetch();
    },
    applyVacancyFilter(filter) {
      const { areas, place } = filter;
      this.filter = { ...this.filter, areas, place };
      this.applyFilter();
    },
    view(vacancy) {
      this.$emit("view", vacancy);
    },
  },
};
</script>
